<template>
    <div class="row-space-tbf focus-page" v-if="loaded">
        <div class="space-left"></div>
        <div class="content opacity-page">
            <section class="focus-section section-header">
                <div class="block-content h1 project-title">
                    <h1 class="block">{{ blockFocus.html }}</h1>
                </div>

                <div class="focus-actions">
                    <!-- add responsible -->
                    <div class="action-item">
                        <button class="no-hover">
                            <template v-if="project.responsible">
                                <div class="icon"><img class="avatar" :src="project.responsible.avatar"/></div>
                                <div class="text">{{ project.responsible.name }}</div>
                            </template>
                            <template v-else>
                                <div class="icon"><icon-user /></div>
                                <div class="text placeholder">{{ $t('general.responsible') }}</div>
                            </template>
                        </button>
                    </div>

                    <div class="action-item">
                        <button class="no-hover">
                            <template v-if="project.users.length">
                                <div class="users-avatars">
                                    <div class="image" v-for="user in project.users.slice(0, 2)">
                                        <img :src="user.avatar">
                                    </div>
                                    <div class="image plus-icon" v-if="project.users.length > 2">
                                        <icon-plus />
                                        <span class="numer">{{ project.users.length - 2 }}</span>
                                    </div>
                                </div>
                                <div class="text">{{ $t('projects.contributors') }}</div>
                            </template>
                            <template v-else>
                                <div class="icon"><icon-people /></div>
                                <div class="text placeholder">{{ $t('projects.contributors') }}</div>
                            </template>
                        </button>
                    </div>

                    <section class="main-actions">
                        <div class="last-update">{{ $t('general.last_update') }} <span class="date">{{ project.updated_at | moment('DD MMM YYYY, hh:ss') }}</span></div>
                        <div class="crud-actions" v-if="project.rights.editor">
                            <button class="btn-tbf-link blue" @click="$router.push({ name: 'projects-edit', params: {slug: project.slug} })"><span class="text">{{ $t('general.edit') }}</span></button>
                        </div>
                    </section>
                </div>
            </section>

            <section class="focus-section">
                <div :class="`block-content ${block.tag}`" v-for="block in blocksBody">
                    <div class="task-block" :class="{done: block.done === 1}" v-if="block.tag == 'task'">
                        <v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" :disabled="!block.frequency">
                            <div class="checkbox-task" :class="{disabled: !checkCanComplete(block)}" @click="handleCheckTask(block)">
                                <icon-check />
                            </div>

                            <template slot="popover">
                                <div class="title">{{ $t('projects.done_disabled') }}</div>
                            </template>
                        </v-popover>
                        <div class="text-task block" v-html="block.html"></div>
                        <div class="task-actions">
                            <div class="action-item" v-if="block.frequency">
                                <v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{show: 0, hide: 0}">
                                    <button class="disabled">
                                        <div class="icon">
                                            <icon-repeat />
                                        </div>
                                    </button>
                                    <template slot="popover">
                                        <div class="title">{{ $t('projects.repetitive_task') }}</div>
                                    </template>
                                </v-popover>
                            </div>
                            <div class="action-item">
                                <button class="no-hover">
                                    <div class="icon">
                                        <img class="avatar" :src="users.find(el => el.id == block.responsible_id).avatar" v-if="block.responsible_id"/>
                                        <icon-user v-else />
                                    </div>
                                </button>
                            </div>
                            <div class="action-item">
                                <button class="no-hover">
                                    <div class="icon"><icon-flag :class="`icon ${block.priority ? block.priority.color : ''}`"/></div>
                                </button>
                            </div>
                            <div class="action-item">
                                <button class="b-pad-0" :class="{disabled: !checkCanComplete(block)}" :disabled="!checkCanComplete(block)" @click="openModalTask(block)">
                                    <div class="icon">
                                        <icon-dots />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="file-block" :class="{empty: !block.fileUrl}" :id="`block-${block.id}`" v-else-if="block.tag == 'file'">
                        <template v-if="block.fileUrl">
                            <a class="label" :href="`/build${block.fileUrl.substring(block.fileUrl.lastIndexOf('.ro')+3)}`" :download="block.fileName">
                                <div class="icon"><icon-file /></div>
                                <div class="name">{{ block.fileName }}</div>
                            </a>
                        </template>
                        <label class="label" v-else>
                            <div class="icon"><icon-file-upload /></div>
                            <div class="name">{{ $t('projects.upload_file') }}</div>
                        </label>
                    </div>
                    <component 
                    class="block" 
                    v-html="block.html" 
                    :id="`block-${block.id}`" 
                    :is="block.tag"
                    v-else />
                </div>
            </section>
        </div>
        <div class="space-right"></div>
    </div>
</template>

<script>
import IconUser from '@/components/Icons/User'
import IconPeople from '@/components/Icons/People'
import IconTags from '@/components/Icons/Tags'
import IconDate from '@/components/Icons/Date'
import IconEditors from '@/components/Icons/Editors'
import IconPlus from '@/components/Icons/Plus'
import IconFile from '@/components/Icons/File';
import IconFileUpload from '@/components/Icons/FileUpload';


import IconCheck from '@/components/Icons/Check';
import IconFlag from '@/components/Icons/Flag';
import IconRepeat from '@/components/Icons/Repeat';
import IconDots from '@/components/Icons/EditDots';

import { mapGetters } from 'vuex'
import { debounce } from "debounce";

export default {
    components: {
        IconUser,
        IconPeople,
        IconTags,
        IconDate,
        IconEditors,
        IconPlus,
        IconCheck,
        IconFlag,
        IconRepeat,
        IconDots,
        IconFile,
        IconFileUpload
    },
    data() {
        return {
            loaded: false,
            project: {},
            users: []
        };
    },
    computed: {
        ...mapGetters({
            blockId: 'blocks/blockId',
            blocksFilter: 'blocks/blocksFilter',
        }),
        blocks: {
            get: function () {
                return this.$store.state.blocks.blocks;
            }
        },
        blockFocus() {
            return this.blockId(1);
        },
        blocksBody: {
            get: function () {
                return this.blocksFilter('body');
            },
            set: function(newValue) {
                var newOrderBlocks = [];
                newOrderBlocks.push({...this.blockFocus, order: 0});

                newValue.map((el, index) => {
                    newOrderBlocks.push({...el, order: index + 1});
                })

                this.$store.dispatch('blocks/editEntireBlocks', newOrderBlocks);
            }
        },
        blocksTasks() {
            return this.blocksFilter('tasks');
        },
    },
    created() { 
        this.debouncedHandleDoneTask = debounce( (data) => { this.checkTask(data); }, 1000);
    },
    beforeDestroy () {
        this.$root.$off("refreshProjectPage");
    },
    async mounted() {
        await this.getFilters();
        await this.getProjectData();

        this.$root.$on('refreshProjectPage', () => { this.getProjectData() } );
    }, 
    methods: {
        async getFilters() {
            await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {
                users: true
            }})
            .then(({data}) => {
                this.users = Object.freeze(data.data.users);
            }).catch(error => {
                if(error.response) {
                    if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            });
        },
        async getProjectData() {
            await axios.get(`/projects/${this.$route.params.slug}/show`)
            .then(({data}) => {
                this.$store.dispatch('blocks/editEntireBlocks', data.data.body);
                this.project = data.data;
            }).catch(error => {
                if(error.response){
                    if(error.response.status == 403) {
                        this.$router.push({name: 'forbbiden'})
                    }else if(error.response.status == 404) {
                        this.$router.push({name: 'not-found'})
                    } else if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            })
            .finally(() => {
                setTimeout(() => {
                    this.loaded = true
                    setTimeout(() => {
                        $('.opacity-page').addClass('show')
                    }, 0)
                }, 0)
            })
        },
        updateProject() {
            var formData = {};
            formData.body = this.blocks;

            axios.patch(`/projects/${this.project.slug}`, formData)
            .then(({data}) => {
                // this.$store.dispatch('blocks/editEntireBlocks', data.data.body);
                // this.project = data.data;
            })
        },
        setCaretToEnd(element) {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(element);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
            element.focus();
        },
        handleCheckTask(blockSelected) {
            if( !this.checkCanComplete(blockSelected) ) { 
                return true;
            } else {

                var newValueDone = blockSelected.done === 1 ? 0 : 1;
                var newDate = '';
                
                if(!blockSelected.date) {
                    newDate = moment().format('YYYY-MM-DD')
                } else {
                    newDate = moment(blockSelected.date).format('YYYY-MM-DD');
                }
                
                this.$store.dispatch('blocks/editBlock', { ...blockSelected, done: newValueDone, date: newDate });

                this.debouncedHandleDoneTask({ task_id: blockSelected.task_id , date: newDate, done: newValueDone });
            }
        },
        checkTask(formData) {
            axios.post(`/tasks/${formData.task_id}/check`, formData)
            this.updateProject();
        },
        openModalTask(blockSelected) {
            if(blockSelected.task_id) {
                var dataModal = { 
                    crud: 'edit', 
                    taskEditId: blockSelected.task_id, 
                    // type: 'task', 
                    date: blockSelected.date ? blockSelected.date : '',
                    from: 'project'
                };

                this.$root.$emit('open_modal', 'add_task', dataModal, false);
            }
        },
        checkCanComplete(blockSelected) {
            var canComplete = false;

            if(blockSelected.task_id) {
                if(this.project.rights.editor) {
                    canComplete = true;
                } else if (blockSelected.responsible && this.$auth.user().id == blockSelected.responsible.id) {
                    canComplete = true;
                }
            }

            return canComplete;
        }
    },
};
</script>